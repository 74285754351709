<template>
  <b-card>
    <loading-grow v-if="isLoading" />
    <div id="NoprintTable">
      <b-row>
        <b-col
          lg="2"
          md="3"
          sm="12"
        >
          <label
            class="mr-1"
            style="font-size: 16px; font-weight: bold;"
          >Pencarian :</label>
        </b-col>
        <b-col
          lg="6"
          md="6"
          sm="12"
        >
          <b-form-input
            v-model="searchTerm"
            placeholder="Masukkan kata kunci pencarian disini"
            type="text"
            class="d-inline-block"
            style="margin-bottom : 10px;"
          />
        </b-col>
        <b-col
          lg="2"
          md="3"
          sm="12"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="danger"
            @click="cetakDataListTrans"
          >
            Print
          </b-button>
        </b-col>
      </b-row>
      <br>
      <!-- table -->
      <vue-good-table
        ref="dataListTrans"
        :columns="columns"
        :rows="rows"
        :rtl="direction"
        :select-options="{ enabled: true }"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm }"
        :pagination-options="{
          enabled: true,
          perPage:pageLength
        }"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >

          <!-- Column: Status -->

          <span v-if="props.column.field === 'status'">
            <b-badge :variant="paymentVariant(props.row.status)">
              {{ props.row.status }}
            </b-badge>
          </span>

          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'action'">
            <span>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                :to="{name: 'user-customer-list-trans-detail', params: {id: props.row.transId}}"
                size="sm"
                variant="outline-secondary"
              >
                View Detail
              </b-button>
              <b-button
                v-if="props.row.typeBayar === 'KREDIT'"
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                v-b-modal.logTrans
                size="sm"
                variant="outline-danger"
                @click="getLogTrans(props.row.transId)"
              >
                Log Pemb.
              </b-button>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap">
                Showing 1 to
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['3','5','10','25','50','100']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
              />
              <span class="text-nowrap "> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
      <!-- table -->
    </div>
    <div
      id="printData"
      class="container"
      style="display : none;"
    >
      <div
        class="row"
        style="margin-bottom: 25px"
      >
        <div class="col-md-3">
          <b-img
            v-if="userData.avatar"
            :src="userData.avatar"
            alt="Logo POS Retail"
            style="margin-bottom : 20px; width: 100%;"
          />
          <b-img
            v-else
            :src="require('@/assets/images/logo/POSRetailBlack.png')"
            alt="Logo POS Retail"
            style="margin-bottom : 20px; width: 100%"
          />
        </div>
        <div class="col-md-9">
          <table width="100%">
            <tbody>
              <tr>
                <td>
                  Nama Toko
                </td>
                <td>:</td>
                <td style="padding-left: 5%">
                  {{ userData.shopName }}
                </td>
              </tr>
              <tr>
                <td>
                  No. Telp
                </td>
                <td>:</td>
                <td style="padding-left: 5%">
                  {{ userData.shopNumber }}
                </td>
              </tr>
              <tr>
                <td>
                  Alamat
                </td>
                <td>:</td>
                <td style="padding-left: 5%">
                  {{ userData.ownerAddress }}
                </td>
              </tr>
              <tr>
                <td>
                  Tanggal Cetak
                </td>
                <td>:</td>
                <td style="padding-left: 5%">
                  {{ printDate }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <table
        width="100%"
        border="1"
      >
        <thead style="text-align: center; background: #efefef !important;">
          <th>Kode Penjualan</th>
          <th>Customer</th>
          <th>Ref. Code</th>
          <th>Sub. Total</th>
          <th>Diskon</th>
          <th>Pajak</th>
          <th>Ongkir</th>
          <th>Type Pembayaran</th>
          <th>Status</th>
        </thead>
        <tbody style="font-size: 11px;">
          <tr
            v-for="(item) in dataListTrans"
            :id="item.transId"
            :key="item.transId"
            ref="row"
          >
            <td>
              {{ item.saleCode }}
            </td>
            <td>
              {{ item.customer }}
            </td>
            <td style="text-align: center">
              {{ item.refCode }}
            </td>
            <td style="text-align: right">
              {{ formatPrice(item.subTotal) }}
            </td>
            <td style="text-align: right">
              {{ formatPrice(item.diskon) }}
            </td>
            <td style="text-align: right">
              {{ formatPrice(item.pajak) }}
            </td>
            <td style="text-align: right">
              {{ formatPrice(item.ongkir) }}
            </td>
            <td style="text-align: center">
              {{ item.typeBayar }}
            </td>
            <td style="text-align: center">
              {{ item.status }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- Modal Section -->
    <log-modal :transid="selectedTransId" />
    <alert-token />
  </b-card>
</template>

<script>
import {
  BRow, BCol, BButton, BPagination, BFormInput, BFormSelect, BBadge, BCard, BImg,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { useRouter } from '@core/utils/utils'
import store from '@/store/index'
import Ripple from 'vue-ripple-directive'
import ApiService from '@/connection/apiService'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import LoadingGrow from '@core/components/loading-process/LoadingGrow.vue'
import AlertToken from '@core/components/expired-token/AlertToken.vue'
import LogModal from './modals/LogModal.vue'

const appService = new ApiService()

export default {
  components: {
    BRow,
    BCol,
    BImg,
    BButton,
    VueGoodTable,
    BPagination,
    // BFormGroup,
    BFormInput,
    BFormSelect,
    BBadge,
    BCard,
    LogModal,
    LoadingGrow,
    AlertToken,
  },
  directives: {
    // 'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      userData: null,
      isLoading: false,
      customerID: this.id,
      selectedPembayaran: null,
      selectedStatus: null,
      selectedTransId: null,
      pembayaranItems: [
        {
          value: null,
          text: 'Semua',
          // disabled: true,
        },
        {
          value: 'CASH',
          text: 'CASH',
        },
        {
          value: 'Kredit / Hutang',
          text: 'KREDIT',
        },
      ],
      statusItems: [
        {
          value: null,
          text: 'Semua',
          // disabled: true,
        },
        {
          value: 'Lunas',
          text: 'Lunas',
        },
        {
          value: 'Belum Lunas',
          text: 'Belum Lunas',
        },
      ],
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: 'Encoded ID',
          field: 'encodedID',
          hidden: true,
        },
        {
          label: 'Kode Penjualan',
          field: 'saleCode',
        },
        {
          label: 'Customer',
          field: 'customer',
        },
        {
          label: 'Ref. Code',
          field: 'refCode',
        },
        {
          label: 'Sub. Total',
          field: 'subTotal',
          type: 'number',
          tdClass: 'text-right',
          formatFn: this.formatPrice,
        },
        {
          label: 'Diskon',
          field: 'diskon',
          type: 'number',
          tdClass: 'text-right',
          formatFn: this.formatPrice,
        },
        /* {
          label: 'Pajak',
          field: 'pajak',
          tdClass: 'text-right',
          formatFn: this.formatPrice,
        }, */
        {
          label: 'Ongkir',
          field: 'ongkir',
          type: 'number',
          tdClass: 'text-right',
          formatFn: this.formatPrice,
        },
        {
          label: 'Grand Total',
          field: 'grandtotal',
          type: 'number',
          tdClass: 'text-right',
          formatFn: this.formatPrice,
        },
        {
          label: 'Pembayaran',
          field: 'typeBayar',
          filterOptions: {
            enabled: true,
            filterDropdownItems: ['CASH', 'KREDIT'],
          },
        },
        {
          label: 'Status',
          field: 'status',
          filterOptions: {
            enabled: true,
            filterDropdownItems: ['PAID', 'UNPAID'],
          },
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
      ],
      rows: [],
      dataListTrans: [],
      searchTerm: '',
    }
  },
  setup() {
    const { route } = useRouter()
    const { id } = route.value.params

    return {
      id,
    }
  },
  computed: {
    salesVariant() {
      const statusColor = {
        Draft: 'light-primary',
        Completed: 'light-secondary',
      }
      return status => statusColor[status]
    },
    paymentVariant() {
      const statusColor = {
        PAID: 'light-secondary',
        UNPAID: 'light-primary',
      }
      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  destroyed() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', this.menuHidden)
  },
  created() {
    this.getDataUser()
    this.fetchListTransaksi()
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', true)
  },
  methods: {
    getWaktuCetak() {
      const currentdate = new Date()
      const tanggal = parseInt(currentdate.getDate(), 10) < 10 ? `0${currentdate.getDate()}` : currentdate.getDate()
      const bulan = parseInt(currentdate.getMonth() + 1, 10) < 10 ? `0${currentdate.getMonth() + 1}` : currentdate.getMonth() + 1
      const tahun = parseInt(currentdate.getFullYear(), 10) < 10 ? `0${currentdate.getFullYear()}` : currentdate.getFullYear()
      const jam = parseInt(currentdate.getHours(), 10) < 10 ? `0${currentdate.getHours()}` : currentdate.getHours()
      const menit = parseInt(currentdate.getMinutes(), 10) < 10 ? `0${currentdate.getMinutes()}` : currentdate.getMinutes()
      const detik = parseInt(currentdate.getSeconds(), 10) < 10 ? `0${currentdate.getSeconds()}` : currentdate.getSeconds()
      // console.log(`${tahun}-${bulan}-${tanggal} ${jam}:${menit}:${detik}`)
      return `${tahun}-${bulan}-${tanggal} ${jam}:${menit}:${detik}`
    },
    getDataUser() {
      const userData = JSON.parse(localStorage.getItem('userData'))
      this.userData = userData
      const timeElapsed = Date.now()
      const today = new Date(timeElapsed)
      // this.printDate  = today.toUTCString()
      console.log(today.toUTCString())
      this.printDate = this.getWaktuCetak()
    },
    cetakDataListTrans() {
      this.isLoading = true
      const { selectedRows } = this.$refs.dataListTrans
      if (selectedRows.length < 1) {
        this.dataListTrans = this.rows
        // this.$router.push({ name: 'customer-history-trans-print', params: { dataListTrans: this.rows } })
      } else {
        this.dataListTrans = selectedRows
        // this.$router.push({ name: 'customer-history-trans-print', params: { dataListTrans: selectedRows } })
      }
      this.isLoading = false
      setTimeout(() => {
        window.print()
        // this.printLandscape()
      }, 2000)
    },
    printLandscape() {
      const localOptions = {
        styles: [
          'https://cdn.jsdelivr.net/npm/vue-good-table@2.18.1/dist/vue-good-table.min.css',
          'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
          'https://unpkg.com/kidlat-css/css/kidlat.css',
        ],
      }
      this.$htmlToPaper('printData', localOptions, () => {
        console.warn('done')
      })
    },
    formatPrice(value) {
      const val = (value / 1).toFixed(0).replace('.', ',')
      const formatedval = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      return `Rp. ${formatedval}`
    },
    getLogTrans(transid) {
      this.selectedTransId = transid
    },
    fetchListTransaksi() {
      this.isLoading = true
      appService.historyList({ id_customer: this.customerID }).then(response => {
        const datares = response.data.data
        const { data } = response
        this.isLoading = false
        if (data.result) {
          if (datares) {
            datares.forEach(this.setRows)
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Data Tidak Ditemukan',
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: 'Data Tidak Ditemukan, Mungkin Terjadi Kesalahan',
              },
            })
          }
        } else {
          this.$bvModal.show('tokenExpired')
        }
      }).catch(err => {
        console.log(err)
      })
    },
    setRows(data) {
      const res = {
        transId: data.id,
        saleCode: data.kode_transaksi,
        customer: data.customer.nama ?? '-',
        refCode: data.no_references ?? '-',
        subTotal: parseInt(data.sub_total, 10),
        diskon: parseInt(data.discount, 10),
        pajak: parseInt(data.pajak, 10),
        ongkir: parseInt(data.ongkir, 10),
        grandtotal: (parseInt(data.sub_total, 10) + parseInt(data.pajak, 10) + parseInt(data.ongkir, 10)) - parseInt(data.discount, 10),
        typeBayar: data.type_pembayaran,
        status: data.status,
      }
      this.rows.push(res)
    },
  },
}
</script>

<style lang="scss">
.vgt-table {
  font-size: 12px !important;
}
</style>

<style lang="scss">
@media print {

  // Global Styles
  body {
    background-color: transparent !important;
    // width: 80mm;
    // height: 100mm;
  }
  .btn-scroll-to-top, .content-header, .bt-print, .horizontal-menu-wrapper, #NoprintTable {
    display: none !important;
  }
  #printData {
    display: block !important;
    margin-top: -100px;
  }
  nav.header-navbar {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .printstruck {
    margin-top: -100px;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }
}
</style>
